import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setOrderDeliveryInfo,
  setOrderStatus,
  checkActivityLevel,
  updateOrderItems,
  setOrderInfo
} from "state/orderEdition/actions";
import { Form, Row, Col } from "react-bootstrap";
import swal from "sweetalert";
import moment from "moment";

const HeaderOrderInfoComponent = ({ orderIndex, showStatus, tenderCatalog, products, productPackages, productGroups }) => {
  const dispatch = useDispatch();
  const deliveryInfo = useSelector(
    (state) => state.orderEdition.orders[orderIndex]?.deliveryInfo
  );
  
  const items = useSelector((state) => state.orderEdition.orders[orderIndex].items);

  const order = useSelector((state) => state.orderEdition.orders[orderIndex]);

  const [deliveryDate, setDeliveryDate] = useState(deliveryInfo?.deliveryDate || "");
  const [person, setPerson] = useState(deliveryInfo?.person || "");
  const [startAt, setStartAt] = useState(deliveryInfo?.startAt || "");
  const [endAt, setEndAt] = useState(deliveryInfo?.endAt || "");
  const [arrivedAtStart, setArrivedAtStart] = useState(deliveryInfo?.arrivedAtStart || "");
  const [arrivedAtEnd, setArrivedAtEnd] = useState(deliveryInfo?.arrivedAtEnd || "");
  const [blanket, setBlanket] = useState(deliveryInfo?.blanket || false);
  const [withService, setWithService] = useState(deliveryInfo?.service || false);
  const [packagingType, setPackagingType] = useState(order.packagingType || "");

  // Synchroniser l'état local avec le store quand deliveryInfo change
  useEffect(() => {
    setDeliveryDate(deliveryInfo?.deliveryDate || "");
    setPerson(deliveryInfo?.person || "");
    setStartAt(deliveryInfo?.startAt || "");
    setEndAt(deliveryInfo?.endAt || "");
    setArrivedAtStart(deliveryInfo?.arrivedAtStart || "");
    setArrivedAtEnd(deliveryInfo?.arrivedAtEnd || "");
    setBlanket(deliveryInfo?.blanket || false);
    setWithService(deliveryInfo?.service || false);
  }, [deliveryInfo]);

  useEffect(() => {
    setPackagingType(order?.packagingType || "");
  }, [order]);

  const handleInputChange = (field, value) => {
    if (['packagingType'].includes(field)) {
      dispatch(
        setOrderInfo(orderIndex, {
          ...order,
          packagingType: value && value !== "" ? value : null
        })
      );
    }
    else {
      const updatedDeliveryInfo = { ...deliveryInfo, [field]: value };
      dispatch(setOrderDeliveryInfo(orderIndex, updatedDeliveryInfo));
    }
    if ('person' === field && value && parseInt(value) > 0) {
      updateItemPrice(parseInt(value));
    }
  };

  // Quand le nombre de personnes change, et si AO, regarde dans le catalogue AO si les items ont des prix pour ce nombre de personnes
  const updateItemPrice = (newPerson) => {
    if (newPerson && parseInt(newPerson) > 0 && tenderCatalog && prestationType) {

      const updatedItems = items.map((item) => {
          const tenderCatalogItem = tenderCatalog?.items?.find((i) => {
            if (i.type === 'product' && i.product_id === item.product_id) {
              return true;
            }
            else if (i.type === 'package' && i.package_id === item.package_id) {
              return true;
            }
            else if (i.type === 'group' && i.group_id === item.group_id) {
              return true;
            }
            return false;
          });

          if (!tenderCatalogItem) {
            return item;
          }

          let packageParticipantsNumber =  1;
          let itemInitialPrice = null;
          if (item.type === 'productPackage' && productPackages) {
            const productPackage = productPackages.find((g) => g.id === Number(item.package_id));
            if (productPackage) {
              packageParticipantsNumber = productPackage.participants_number || 1;
              itemInitialPrice = productPackage.price;
            }
          }
          if (item.type === 'product') {
            const product = products.find((g) => g.id === Number(item.product_id));
            if (product) {
              itemInitialPrice = product.price;
            }
          }
          if (item.type === 'productGroup') {
            const productGroup = productGroups.find((g) => g.id === Number(item.group_id));
            if (productGroup) {
              itemInitialPrice = productGroup.price;
            }
          }

          let tenderCatalogItemPricing = tenderCatalogItem?.pricings?.find((pricing) => 
            pricing.min_persons <= newPerson &&
            pricing.max_persons >= newPerson &&
            pricing.price !== null && // Vérifie que le palier a un prix valide
            pricing.prestation_types.includes(prestationType)
          );
          
          // Si aucun palier direct ne correspond avec un prix valide
          if (!tenderCatalogItemPricing) {
            const pricingsWithPrice = tenderCatalogItem?.pricings?.filter((pricing) => 
              pricing.price !== null && // Prend seulement les paliers avec un prix valide
              pricing.prestation_types.includes(prestationType) &&
              pricing.min_persons <= newPerson // Exclut les paliers où newPerson est inférieur au minimum
            );
          
            // Trouve le palier le plus proche par distance absolue
            const closestPricing = pricingsWithPrice?.reduce((closest, pricing) => {
              const currentDistance = Math.abs(pricing.max_persons - newPerson);
              const closestDistance = Math.abs(closest.max_persons - newPerson);
              return currentDistance < closestDistance ? pricing : closest;
            }, pricingsWithPrice[0]);
          
            // Utilise le palier le plus proche si trouvé
            if (closestPricing) {
              tenderCatalogItemPricing = closestPricing;
            }
          }

          let finalPrice = item.price;
          if (tenderCatalogItem) {
            if (tenderCatalogItemPricing && tenderCatalogItemPricing.price !== null) {
              finalPrice = tenderCatalogItemPricing.price / packageParticipantsNumber;
            }
            else if (tenderCatalogItem.price !== null) {
              finalPrice = tenderCatalogItem.price / packageParticipantsNumber;
            }
            else if (itemInitialPrice !== null) {
              finalPrice = itemInitialPrice / packageParticipantsNumber;
            }
            else {
              finalPrice = item.price
            }
          }
          else {
            finalPrice = item.price
          }
          return {
            ...item,
            price: finalPrice
          };
      });
      dispatch(updateOrderItems(orderIndex, updatedItems));
    }
  };

  const orderStatus = useSelector(
    (state) => state.orderEdition.orders[orderIndex].status
  );
  const prestationType = useSelector(
    (state) => state.orderEdition.orders[orderIndex].prestationType
  );
  const [status, setStatus] = useState(orderStatus || "");

  useEffect(() => {
    if (status !== orderStatus) {
      dispatch(setOrderStatus(orderIndex, status));
    }
  }, [dispatch, orderIndex, orderStatus, status]);

  let statusOptions = [
    {
      option: "101",
      traduction: "En attente",
    },
    {
      option: "102",
      traduction: "Validé",
    },
    {
      option: "103",
      traduction: "A vérifier avant facturation",
    },
    {
      option: "401",
      traduction: "Refusé par le client",
    },
  ];

  const handleDeliveryDateChange = async (e) => {
    // Si la date est un week end, on lance une popup pour prévenir l'utilisateur
    let confirmWeekend = true;
    const date = new Date(e.target.value);
    const formattedDate = moment(date).format("YYYY-MM-DD");
    if (e.target.value) {
      if (date.getDay() === 0 || date.getDay() === 6) {
        confirmWeekend = await 
        swal({
          title: "Attention ",
          text: "Ce jour est un week-end, êtes-vous sûr de vouloir continuer ?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((confirm) => {
          return confirm;
        }
        );
      }
    }
    if (!confirmWeekend) {
      return;
    }

    // Niveau d'activité
    let confirmActivity = true;
    confirmActivity = await dispatch(checkActivityLevel(prestationType, formattedDate)).then(
      (response) => {
        let swalMsg = null;
        if (response?.isClosed === true) {
          swalMsg = "L'activité est bloqué pour cette date, êtes-vous sûr de vouloir continuer ?";
        }
        if (response?.activityLevelTxt) {
          if ('medium' === response.activityLevelTxt) {
            swalMsg = "L'activité est forte pour cette date, êtes-vous sûr de vouloir continuer ?";
          }
          else if ('high' === response.activityLevelTxt) {
            swalMsg = "L'activité est bloqué pour cette date, êtes-vous sûr de vouloir continuer ?";
          }
          else if ('low' === response.activityLevelTxt) {
            swalMsg = "L'activité est faible pour cette date, êtes-vous sûr de vouloir continuer ?";
          }
        }

        if (swalMsg && 'low' !== response.activityLevelTxt) {
          return swal({
            title: "Attention ",
            text: swalMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((confirm) => {
            return confirm;
          });
        }
        return true;
      }
    );
    if (!confirmActivity) {
      return;
    }

    setDeliveryDate(formattedDate);
    handleInputChange("deliveryDate", formattedDate);
  }

  return (
    <>
      <Row>
        {showStatus && (
          <Col>
            <Form.Group controlId="statusSelect">
              <Form.Label>Statut*</Form.Label>
              <Form.Control
                as="select"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Sélectionnez un statut</option>
                {statusOptions.map((sta, index) => (
                  <option key={index} value={sta.option}>
                    {sta.traduction}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        )}
        <Col>
          <Form.Group controlId="deliveryDate">
            <Form.Label>Date de livraison*</Form.Label>
            <Form.Control
              type="date"
              value={deliveryDate}
              onChange={(e) => {
                handleDeliveryDateChange(e);
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="person">
            <Form.Label>Nombre de personnes*</Form.Label>
            <Form.Control
              type="number"
              value={person}
              onChange={(e) => {
                setPerson(e.target.value);
                handleInputChange("person", e.target.value);
              }}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="packagingType">
            <Form.Label>Type de packaging</Form.Label>
            <Form.Control
              as="select"
              value={packagingType}
              onChange={(e) => {
                setPackagingType(e.target.value);
                handleInputChange("packagingType", e.target.value);
              }}
            >
              <option value="">Normal</option>
              <option value="zero_waste">Zéro déchet</option>
              <option value="picnic">Pique-nique</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="arrivedAtStart">
            <Form.Label>Début livraison</Form.Label>
            <Form.Control
              type="time"
              value={arrivedAtStart}
              onChange={(e) => {
                setArrivedAtStart(e.target.value);
                handleInputChange("arrivedAtStart", e.target.value);
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="arrivedAtEnd">
            <Form.Label>Fin livraison</Form.Label>
            <Form.Control
              type="time"
              value={arrivedAtEnd}
              onChange={(e) => {
                setArrivedAtEnd(e.target.value);
                handleInputChange("arrivedAtEnd", e.target.value);
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="startAt">
            <Form.Label>Début presta</Form.Label>
            <Form.Control
              type="time"
              value={startAt}
              onChange={(e) => {
                setStartAt(e.target.value);
                handleInputChange("startAt", e.target.value);
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="endAt">
            <Form.Label>Récupération</Form.Label>
            <Form.Control
              type="time"
              value={endAt}
              onChange={(e) => {
                setEndAt(e.target.value);
                handleInputChange("endAt", e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="blanket">
            <Form.Check
              type="checkbox"
              label="Nappe"
              checked={blanket}
              onChange={(e) => {
                setBlanket(e.target.checked);
                handleInputChange("blanket", e.target.checked);
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="withService">
            <Form.Check
              type="checkbox"
              label="Avec service"
              checked={withService}
              onChange={(e) => {
                setWithService(e.target.checked);
                handleInputChange("service", e.target.checked);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default HeaderOrderInfoComponent;